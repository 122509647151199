// NOTE: Do not use this for future icons. Use unicons instead, go to README.md for more info.
// Icons below are not in Unicons library

@font-face {
  font-family: 'icomoon';
  src: url('../../../assets/fonts/icons/icomoon.eot?czo9kz');
  src: url('../../../assets/fonts/icons/icomoon.eot?czo9kz#iefix') format('embedded-opentype'),
    url('../../../assets/fonts/icons/icomoon.ttf?czo9kz') format('truetype'),
    url('../../../assets/fonts/icons/icomoon.woff?czo9kz') format('woff'),
    url('../../../assets/fonts/icons/icomoon.svg?czo9kz#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'],
.ng-arrow {
  /* use !important to prevent issues with browser extensions that change fonts */
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  &:before {
    font-family: 'icomoon', sans-serif !important;
  }

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-type-email:before {
  content: '\e918';
}

.icon-type-pending:before {
  content: '\e919';
}

.icon-type-error:before {
  content: '\e91a';
}

.icon-type-logout:before {
  content: '\e91c';
}

.icon-empty-screen:before {
  content: '\e917';
}

.uil-angle-down:before,
.ng-arrow:before {
  content: '\e90d';
}

.icon-divide:before {
  content: '\2503';
}

.icon-equals:before {
  content: '\003D';
}

.icon-circle-current::before {
  content: '\1F787';
}

.icon-green-check {
  background: url('../../../assets/images/success-icon.svg') no-repeat;
  background-size: contain;
  width: 91px;
  height: 91px;
  margin-bottom: 30px;
}
