@import 'variables';

.pp-slider.mat-slider {
  .mat-slider-thumb-container,
  .mat-slider-thumb-label {
    transition: none;
  }

  &.cdk-focused .mat-slider-thumb-label {
    top: -47px !important;
    right: 0 !important;
    border-radius: 5px !important;
    transform: rotate(0deg) translateX(50%) !important;
    width: unset;
    padding: 10px;
    background-color: #fff !important;
    filter: drop-shadow(0px 4px 17px rgba(6, 6, 11, 0.06));

    &::after {
      content: '';
      position: absolute;
      height: 20px;
      width: 20px;
      display: block;
      background: #fff;
      bottom: -50%;
      transform: translateY(-50%) rotate(45deg);
    }
  }
}

.mat-slider.cdk-focused {
  .mat-slider-thumb-container .mat-slider-thumb {
    transform: scale(1.1) !important;
    border: 2px solid #fff !important;
  }
}

.mat-slider-thumb-label-text {
  transform: rotate(0deg) !important;
  color: #404548 !important;
}

.pp-slider.mat-slider-horizontal .mat-slider-wrapper {
  left: 0;
  right: 0;
}

.mat-slider {
  width: 100%;

  .mat-slider-track-fill,
  .mat-slider-wrapper,
  .mat-slider-track-wrapper,
  .mat-slider-track-background {
    height: 6px !important;
    border-radius: 50px;
    background-color: $stepperColor;
  }

  .mat-slider-thumb {
    background-color: $grey !important;
  }
}

.mat-slider:not(.mat-slider-disabled) {
  .mat-slider-track-fill,
  .mat-slider-thumb-label,
  .mat-slider-thumb {
    background-color: $primary !important;
  }
}
