@import 'mixins';
@import 'variables';

.popup-calendar-dialog {
  .ngb-dp-month {
    width: 100%;
  }

  .ngb-dp-week {
    justify-content: space-evenly;
    border-bottom: 0;
  }

  .ngb-dp-weekday {
    color: $primary;
  }

  .ngb-dp-month-name {
    font-weight: 700;
  }

  .ngb-dp-header,
  .ngb-dp-week,
  .ngb-dp-month-name {
    background-color: #fff;
  }

  @include maxMobileL {
    margin: 0;
    max-width: 100% !important;
    height: 100%;

    .modal-content {
      border-radius: 0;
      height: 100%;
    }
  }
}

.ngb-dp-day div[ngbdatepickerdayview] {
  border-radius: 50%;
}
