.mat-menu-panel {
  background-color: #fff;
  min-height: fit-content !important;
}

.mat-menu-item {
  background-color: #fff;
}

.switch-practice-menu > .mat-menu-content:not(:empty) {
  max-height: calc(100vh - 100px);
}
