.accordion {
  .mat-expansion-panel-content,
  .mat-expansion-panel-header {
    font: inherit !important;
  }

  .mat-expansion-panel-body,
  .mat-expansion-panel-header {
    padding: 0;
  }

  .mat-expansion-panel-header {
    height: max-content;
  }

  .mat-expansion-indicator {
    padding: 0 24px;
  }

  .mat-expansion-panel {
    overflow: visible;

    .panel-flex {
      display: flex;
      flex-direction: column;
    }

    .order-2 {
      order: 2;
    }
  }
}
