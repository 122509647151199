@use 'variables';

.radio-btn-card {
  border: 1px solid #f0f0f0;
  box-shadow: 0px 4px 34px rgba(228, 228, 228, 0.1);
  border-radius: 6px;

  .mat-radio-label-content {
    flex-grow: 2;
  }

  .mat-radio-label {
    margin-bottom: 0;
    padding: 1rem;
  }
}

.pp-radio-btn .mat-radio-disabled {
  opacity: 0.7;

  .mat-radio-label {
    cursor: not-allowed;
  }
}

.pp-radio-group {
  .mat-radio-button {
    flex-grow: 1;
  }

  .mat-radio-label {
    margin-bottom: 0;
  }

  .mat-radio-label-content {
    font-size: 12px;
    font-family: 'Open Sans';
  }
}

.pp-flow-option {
  .mat-radio-button,
  .mat-radio-label-content {
    width: 100%;
  }

  .mat-radio-label {
    margin-bottom: 0;
  }
}

.pp-radio-group .mat-radio-disabled .mat-radio-label-content,
.freq-container .mat-radio-disabled .mat-radio-label-content {
  color: $grey;
}

.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: $grey !important;
}

.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: $grey !important;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  opacity: 0; /*click effect color change*/
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: $primary; /*inner circle color change*/
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $primary; /*outer ring color change*/
}

.mat-radio-outer-circle {
  border-color: $grey;
}
