@import '~@ng-select/ng-select/themes/default.theme.css';
@import '../node_modules/ngx-toastr/toastr';
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '~@iconscout/unicons/css/line.css'; // Search icons in https://iconscout.com/unicons/explore/line

@import 'variables';
@import '../node_modules/bootstrap/scss/bootstrap';
@import 'app/styles/main/common';
@import 'app/styles/main/icons';
@import 'app/styles/main/typography';
@import 'app/styles/main/components/components';
@import 'app/styles/main/vendor/vendors';

body {
  background: $bodyBg;
  font-size: 13px;
  color: $mainColor;
  font-family: 'Open Sans', sans-serif;
}

@media print {
  /* Set the paper size to A4 */
  @page {
    size: A4;
  }
}
