@import 'variables';

.pp-dropdown .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  white-space: break-spaces;
}

.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  top: 0;
}

.pp-dropdown .ng-arrow {
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
}

.pp-dropdown.pp-dropdown-center {
  .ng-select-container {
    display: flex;
    justify-content: center;
  }

  .ng-select-container .ng-value-container {
    flex: unset;
    justify-content: center;
  }

  .ng-option {
    text-align: center;
  }

  .ng-arrow-wrapper {
    padding-left: 8px;
  }
}

.pp-dropdown.pp-dropdown-error .ng-select-container {
  @include borderDanger;
}

.ng-select .ng-select-container {
  color: $black;
  min-height: 40px;
}

.primary-color-dropdown {
  .pp-dropdown.ng-select .ng-select-container {
    height: 40px;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .ng-select .ng-select-container {
    border-color: $primary;
    background-color: $primary;
    min-width: 174px;
    width: auto;
  }

  .ng-select .ng-select-container .ng-value-container .ng-placeholder,
  .ng-dropdown-panel .ng-dropdown-panel-items {
    font-weight: 600;
  }

  .ng-select .ng-select-container .ng-value-container .ng-placeholder,
  .ng-select .ng-select-container {
    color: $white;
  }

  .ng-select-disabled > .ng-select-container {
    background-color: #1465aa1a;
    border-color: #1465aa1a;
    pointer-events: none;
  }

  .ng-select.ng-select-disabled .ng-select-container .ng-value-container .ng-placeholder,
  .ng-select.ng-select-disabled .ng-select-container .ng-value-container .ng-value {
    color: $primary;
    opacity: 0.65;
  }

  .ng-select-disabled.ng-select.ng-select-single .ng-select-container .ng-arrow {
    color: $primary;
    opacity: 0.65;
  }

  .ng-dropdown-panel {
    margin-top: 10px;
    box-shadow: 0 7px 34px #3b3b3b1a;
    border-radius: 5px;
    border: none;

    .ng-dropdown-panel-items {
      text-align: left;
    }
  }
}

.ng-select-multiple,
ng-select.ng-select-single {
  .ng-select-container {
    border-color: $borderColor;

    .ng-arrow {
      border: 0;
      font-size: 7px;
      width: auto !important;
      height: auto !important;
    }
  }
}

.ng-select.ng-select-single .ng-select-container {
  height: 42px;
}

.ng-select.ng-select-disabled > .ng-select-container {
  color: $grey;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #9c9c9c;
  font-size: 12px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  padding-top: 0;
  line-height: 30px;
}

.longer-dd .ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 370px;
}
