@import 'variables';

.table {
  line-height: 24px;
  padding: 20px 15px;

  thead {
    background: $tableHeaderBg;

    th {
      border: 0;
      border-right: 1px solid $tableHeaderBg;
      color: $black;
      font-size: 12px;
      font-weight: 400;
      line-height: 19px;
      padding: 8px 14px;
      vertical-align: top;
      white-space: nowrap;

      .sorting {
        padding-right: 15px;
        position: relative;
        display: inline-block;
        cursor: pointer;

        &:after,
        &:before {
          content: '';
          color: #c5c5c5;
          font-size: 4px;
          line-height: 10px;
          position: absolute;
          top: 7px;
          right: 0;
          font-family: 'icomoon', sans-serif !important;
        }

        &:after {
          content: '\e925';
        }

        &:before {
          margin-top: -6px;
          content: '\e926';
        }
      }

      &.asc {
        cursor: pointer;

        .sorting {
          &:before {
            color: $black2;
          }
        }
      }

      &.desc {
        cursor: pointer;

        .sorting {
          &:after {
            color: $black2;
          }
        }
      }
    }
  }

  tr {
    &:first-child {
      td {
        border-top: 0;
      }
    }

    td {
      &:last-child {
        border-right: 0;
      }
    }
  }

  td {
    padding: 13px;
    font-size: 12px;
    vertical-align: middle;
    border-top: 1px solid $borderColor;
    border-right: 1px solid $borderColor;
    white-space: nowrap;
  }

  tfoot {
    background: $tableHeaderBg;

    td {
      border: 0;
      border-right: 1px solid $tableHeaderBg;
      color: $black;
      font-size: 12px;
      font-weight: 400;
      line-height: 19px;
      padding: 8px 14px;
      vertical-align: top;
      white-space: nowrap;
    }
  }
}
