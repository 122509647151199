@use 'variables';

@use '@material/switch/switch' as mdc-switch;
@use '@material/switch/switch-theme' as mdc-switch-theme;

.sliqpay-toggle-switch.mat-mdc-slide-toggle {
  .mdc-switch {
    @include mdc-switch-theme.theme(
      (
        //handle color
        disabled-selected-handle-color: $grey,
        disabled-unselected-handle-color: $grey,

        selected-handle-color: $primary,
        selected-focus-handle-color: $primary,
        selected-hover-handle-color: $primary,
        selected-pressed-handle-color: $primary,

        unselected-handle-color: $white,
        unselected-focus-handle-color: $white,
        unselected-hover-handle-color: $white,
        unselected-pressed-handle-color: $white,

        //tracks color
        disabled-selected-track-color: $grey,
        disabled-unselected-track-color: $grey,

        selected-track-color: $primary-muted,
        selected-focus-track-color: $primary-muted,
        selected-hover-track-color: $primary-muted,
        selected-pressed-track-color: $primary-muted,

        unselected-track-color: $grey,
        unselected-focus-track-color: $grey,
        unselected-hover-track-color: $grey,
        unselected-pressed-track-color: $grey,

        // icon colors
        disabled-selected-icon-color: transparent,
        disabled-unselected-icon-color: transparent,
        selected-icon-color: transparent,
        unselected-icon-color: transparent,

        selected-focus-state-layer-color: $primary-muted,
        selected-hover-state-layer-color: $primary-muted,
        selected-pressed-state-layer-color: $primary-muted
      )
    );
  }

  .mdc-label {
    @include defaultFont();
    font-size: smaller;
    margin-bottom: 0;
    margin-left: 2px;
  }
}

.sliqpay-toggle-switch--sm.mat-mdc-slide-toggle {
  .mdc-switch {
    transform: scale(0.9);
  }
}

.sliqpay-toggle-switch.hide-switch {
  .mdc-switch {
    display: none;
  }

  .mdc-label {
    margin-left: 0;
    padding-left: 0;
  }

  .mdc-switch--disabled + label {
    color: $black;
  }
}
