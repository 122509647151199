$themePath: 'DentiCare';

$white: #ffffff;

$darkGrey: #888e9d;
$secondary: #6c757d;
$success: #10a34e;
$checkSuccess: #27ae60;
$white: #ffffff;
$info: #17a2b8;
$lightSecondary: #f2fff7;
$black: #0f1d36;
$black2: #4a4a4a;
$black3: #272727;
$danger: #dd1e1e;
$borderColor: #f0f0f0;
$borderColorDark: #d8d8d8;
$tableHeaderBg: #fafafa;
$grey: #9c9c9c;
$greyMenu: #e5e5e5;
$textGrey: #909eb9;
$btnGrey: #eef0f3;
$warning: #eeae10;
$labelColor: #4a4a4a;
$iconGreyBg: #f9fbfc;
$bodyBg: #f7f7fa;
$mainColor: #747474;
$mainBorderRadius: 4px;
$stepperColor: #e6e6e6;
$theme-colors: (
  'primary': #1465aa,
  'secondary': #34a67d,
  'warning': #ee914d,
  'danger': #dc2626,
  'info': #34a67d
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1260px
);

$primary: #1465aa;
$primary-muted: rgba(20, 101, 170, 0.5);
$primary-disabled: rgba(20, 101, 170, 0.1);
$primary-light: rgba(20, 101, 170, 0.1);

$sidenav-bg-color: #1465aa;

$btn-bg-primary: rgba(63, 144, 212, 0.1);
$btn-bg-secondary: rgba(108, 117, 125, 1);
$btn-bg-info: rgba(23, 162, 184, 1);
$btn-bg-success: rgba(84, 224, 144, 0.1);
$btn-bg-warning: rgba(246, 191, 55, 0.1);
$btn-bg-danger: rgba(239, 77, 77, 0.1);
$btn-bg-light: rgba(248, 249, 250, 1);
$btn-bg-suspended: rgba(231, 234, 238, 1);
$btn-bg-purple: rgba(95, 93, 163, 0.1);

$badgeViolet: #5f5da3;
