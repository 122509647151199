@use 'variables' as v;
@import 'mixins';

.rounded {
  border-radius: 4px !important;
}

.p-30 {
  padding: 30px;
}

.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-120 {
  width: 120px !important;
}

.w-170 {
  width: 145px !important;
  @include mobileL {
    width: 170px !important;
  }
}
.w-230 {
  width: 230px !important;
}
.ngx-pagination {
  margin-top: 30px;
  padding-left: 0;

  li {
    background: #ffffff;
  }
}

label.required-field::after,
label > .required-field::after {
  content: '*';
  display: inline;
  color: $danger;
  margin-left: 2px;
}

.page-header {
  padding: 25px 10px 15px;
  border-bottom: 2px solid v.$borderColor;

  h2 {
    margin-bottom: 0;
  }
}

.create-btn-container {
  display: flex;
  justify-content: center;
  width: 100%;

  @include tablet {
    width: auto;
  }
}

.plan-container {
  margin-top: 3rem;

  @include tablet {
    margin-top: 0;
  }
}

.page-content {
  margin: 20px;
}

.page-header-sticky {
  position: sticky;
  top: 0;
  z-index: 12;
}

.content {
  padding: 10px 5px;

  @include tablet {
    padding: 20px 15px;
  }

  .table-responsive {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    &.drop-down-opened {
      display: flex;
      flex-direction: row-reverse;
      overflow: visible;
      position: relative;
      &:before {
        content: '';
        left: -30px;
        width: 30px;
        height: 100%;
        display: block;
        background: white;
        position: absolute;
        top: 0;
        z-index: 11;
      }

      @include desktop {
      }
    }
  }

  .dropdown-toggle.btn.without-arrow {
    padding-top: 0;
    padding-bottom: 0;
    outline: none;

    &:focus {
      box-shadow: none;
    }

    &:after {
      display: none;
    }
  }

  .dropdown-menu {
    border: 0;
    font-size: 12px;
    min-width: 240px;
    box-shadow: 0 0 8px rgba(37, 40, 43, 0.12);

    i {
      font-size: 13px;
      margin-right: 13px;
    }

    li {
      line-height: 28px;

      a,
      button {
        color: v.$black;
        padding: 6px 18px;
        font-weight: 600;

        i {
          font-size: 16px;
        }

        &:hover,
        &:focus {
          color: v.$primary;
          background: rgba(0, 126, 255, 0.04);
        }
      }
    }
  }
}

.page-inner-menu {
  border-bottom: 1px solid v.$borderColor;

  ul {
    padding: 10px 0 0;
    margin: 0 0 -1px 0;

    @include tablet {
      padding: 10px 30px 0;
    }

    li {
      list-style: none;
      display: inline-block;
      margin: 0;

      a {
        display: block;
        font-size: 12px;
        line-height: 18px;
        color: v.$grey;
        padding: 12px 14px;
        text-decoration: none;
        border-bottom: 3px solid transparent;

        &:hover,
        &.is-active {
          color: v.$primary;
          border-bottom: 2px solid v.$primary;
        }
      }
    }
  }
}

.status-btn {
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  padding: 3px 12px;
  border-radius: 4px;
  background: v.$lightSecondary;
  color: v.$success;
}

.form-group {
  label {
    font-size: 12px;
    font-weight: 400;
    color: v.$labelColor;

    &.disabled {
      color: v.$grey;
    }
  }

  .form-control {
    width: 100%;
    height: 42px;
    padding: 12px 15px;
    border: 1px solid v.$borderColor;
    box-shadow: inset 4px 4px 11px rgba(212, 212, 212, 0.1);
    border-radius: 3px;
    font-size: 12px;
    line-height: 19px;
    outline: none;
    color: v.$black;

    @include tablet {
      font-size: 12px;
    }

    &:disabled,
    &[readonly] {
      color: v.$grey !important;
      background-color: v.$iconGreyBg !important;
      border-radius: 3px;
      cursor: default;
    }
    &[type='range'],
    &.input-range {
      padding: 0 !important;
    }
  }
}

.invalid-feedback {
  margin-bottom: 0.25rem;
}

//modal
.modal-body {
  padding: 10px;
}

.modal-container {
  padding: 25px;
}

.modal-lg {
  max-width: 640px;
}

.modal-xl {
  max-width: 864px;
}

@include media-breakpoint-only(md) {
  .modal-wide .modal-xl {
    max-width: 90%;
  }
}

.templateModalClass .modal-dialog {
  max-width: 1012px;
}

.close-block {
  text-align: right;
  margin: -15px -15px 0 0;

  .close {
    float: none;
    outline: none;
  }
}

.modal-header {
  padding: 27px 25px 0 25px;
  border-bottom: 0;

  .close {
    opacity: 1;
  }

  i.uil-times {
    color: v.$black;
  }
}

.modal-sm {
  max-width: 408px;
}

ngb-modal-backdrop {
  z-index: 1050 !important;
}

pdf-viewer {
  height: 90vh;
  width: 100%;
}

.html-code-block {
  background: #ffffff;
  border: 1px solid v.$borderColor;
  box-shadow: inset 4px 4px 11px rgba(212, 212, 212, 0.1);
  border-radius: 3px;
  padding: 30px 15px;
  color: v.$black;
  font-size: 12px;
  line-height: 19px;
}

//date picker
.date-picker-group {
  position: relative;

  .uil-search,
  .uil-calendar-alt {
    position: absolute;
    top: 12px;
    left: 14px;
    z-index: 11;
  }

  .uil-calendar-alt {
    color: v.$black;
  }

  .uil-angle-down {
    top: 16px;
    right: 10px;
    z-index: 11;
    font-size: 7px;
    position: absolute;
  }

  .form-control {
    padding-left: 35px;
  }
}
.was-validated .form-control:invalid,
.form-control.is-invalid {
  box-shadow: none;
  background: transparent;
}

.search-field-group,
.search-date-group {
  position: relative;

  .uil-search,
  .uil-calendar-alt {
    position: absolute;
    top: 12px;
    left: 14px;
    z-index: 11;
  }

  .uil-calendar-alt {
    color: v.$black;
  }

  .uil-angle-down {
    top: 10px;
    right: 10px;
    font-size: 1rem;
    z-index: 11;
    position: absolute;
  }

  .form-control {
    padding-left: 35px;
  }
}

//loader
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid v.$primary;
  border-color: v.$primary transparent v.$primary transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

//mobile
.mobile-loading-screen {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: url('../../../assets/images/#{v.$themePath}/loading-screen.jpg') no-repeat;
  background-size: cover;
  text-align: center;
  padding: 50px 20px;
  color: #ffffff;

  .__inner {
    max-width: 400px;
    margin: 0 auto;
  }

  .logo {
    width: 150px;
    margin: 0 auto;

    img {
      width: 100%;
    }
  }

  h1 {
    color: #ffffff;
    font-size: 25px;
    line-height: 38px;
    padding: 170px 0 20px;
  }

  .modal-container {
    padding: 28px;
    text-align: center;
    background: white;
    border-radius: 4px;
    margin-top: 68px;

    .content-icon {
      display: block;
      width: 124px;
      height: 124px;
      margin: 0 auto 22px;
      border-radius: 100%;
      text-align: center;
      line-height: 130px;
      background: v.$iconGreyBg;
      font-size: 64px;

      &:before {
        color: v.$primary;
      }
    }
  }
}

.pp-assistant-screen {
  .modal-dialog {
    margin: 0;
    max-width: 1287px;

    @include tablet {
      margin: 1.5rem auto;
    }
  }
}

.sms-journey-list,
.details-style {
  padding: 0;
  margin: 0;

  li {
    list-style: none;
    padding: 12px 0;
    border-bottom: 1px solid v.$borderColor;
    &:last-child {
      border-bottom: 0;
    }
  }

  label {
    color: v.$mainColor;
    font-size: 12px;
  }

  p {
    color: v.$black;
    font-size: 13px;
    margin-bottom: 0;
  }
}

.payment-plan-header {
  background: white;
  padding: 13px 0;
  border-bottom: 1px solid v.$borderColor;
  width: 100%;
  z-index: 111;
  position: fixed;
  @include tabletL {
    padding: 13px 30px;
  }
}

.auth-page {
  height: 100vh;
  background: url('../../../assets/images/#{v.$themePath}/login-bg.jpg') no-repeat;
  background-size: cover;
  flex-direction: column;
  @include tablet {
    background: #ffffff;
    flex-direction: row;
  }
}

.ol1 {
  padding-left: 30px;
}

.ngx-loading-bar {
  position: fixed !important;
  z-index: 111111111111111 !important;
  pointer-events: all !important;
}

.ngx-bar {
  z-index: 11111111111;
  position: relative;
  background: v.$primary;

  &:after {
    background: rgba(0, 0, 0, 0.15);
    width: 100vw;
    height: 100vh;
    display: block;
    content: '';
    position: relative;
    z-index: 11111;
  }
}

.signature-pad-canvas {
  border: none !important;
}

.mat-stepper-vertical,
.mat-stepper-horizontal {
  font-family: inherit !important;
  background: transparent !important;
}

.btn-primary {
  color: v.$white;
  background-color: v.$primary;
  border-color: v.$primary;
  border-radius: 4px;

  &:disabled {
    background-color: v.$primary-disabled;
    border-color: v.$primary-disabled;
    color: v.$primary;
    pointer-events: none;
  }

  &:hover {
    color: v.$white;
    background-color: v.$primary;
    border-color: v.$primary;
  }
}

a {
  color: v.$primary;
}

input[type='checkbox'],
input[type='radio'] {
  accent-color: v.$primary;
}

// For truncating text and show full on hover
.show-hide-truncate {
  max-width: 21ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  // &:hover {
  //   overflow: visible;
  //   white-space: normal;
  //   height: auto;
  // }
}

.payment-actions-submenu .mat-menu-content {
  min-width: 150px;
}

.dotted {
  border-top: 1px dashed v.$grey !important;
  margin-bottom: 28px !important;
}

.rotate-to {
  &-0 {
    transform: rotate(0deg);
  }

  &-180 {
    transform: rotate(180deg);
  }
}

.pp-datepicker .cdk-overlay-container {
  z-index: 1051;
}

.pt-add-section {
  .modal-dialog {
    max-width: 1024px;
  }
}

.tnc-heading {
  font-weight: 700;
}

// Fix for link of ckeditor when ckeditor is inside a modal
.ck-balloon-panel {
  z-index: 9999 !important;
}

.basiq-connect-bank-box {
  background-color: v.$primary-light;
}

.text-ellipsis {
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-decoration: underline;
  text-underline-offset: 0.3em;
}

.border--danger {
  @include borderDanger;
}

.homepage-action-buttons .btn-primary {
  font-size: 12px;
}

.sliqpay-dynamic-discount {
  .number-input-group input {
    border-radius: 3px 0 0 3px;
    border-right: 0;
  }

  .pp-dropdown .ng-select-container {
    border-radius: 0 3px 3px 0;
  }

  .form-group {
    margin-bottom: 0;
  }
}
