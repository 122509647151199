@use 'variables' as v;

@mixin mobileS {
  @media (min-width: 320px) {
    @content;
  }
}

@mixin mobileM {
  @media (min-width: 375px) {
    @content;
  }
}

@mixin mobileL {
  @media (min-width: 425px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin tabletMiddle {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin tabletL {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin desktopFullHD {
  @media (min-width: 1360px) {
    @content;
  }
}

@mixin maxMobileL {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin maxMobileM {
  @media (max-width: 424px) {
    @content;
  }
}

@mixin maxMobileS {
  @media (max-width: 374px) {
    @content;
  }
}

@mixin defaultFont {
  font-family: 'Open Sans', sans-serif;
}

@mixin borderDanger {
  border: 1px solid $danger !important;
}
