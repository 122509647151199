@import 'variables';

.toast-container {
  .ngx-toastr {
    display: block;
    background: white;
    padding: 12px 20px !important;
    border-radius: 6px;
    min-height: 80px;
    min-width: 360px;
    box-shadow: 0 7px 15px rgba(59, 59, 59, 0.1);
    color: $black;

    .toast-message a,
    .toast-message label {
      color: $black;
    }

    .toast-close-button {
      color: $black;
    }
  }
}
