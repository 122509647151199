@import 'mixins';
@import 'variables';

.pp-checkbox .mat-checkbox-label {
  @include defaultFont;
  line-height: 19px;
  padding-top: 1px;
  font-size: 12px;
  font-weight: 400;
  color: $black;
  white-space: normal;
}

.pp-checkbox.required-field .mat-checkbox-label .label span::before {
  content: '*';
  color: $danger;
  margin-right: 2px;
}

sliqpay-checkbox.ng-touched .pp-checkbox.required-field .mat-checkbox-frame {
  border-color: $danger;
}

.pp-checkbox.checkbox-md .mat-checkbox-inner-container {
  width: 21px;
  height: 21px;
}
