@import 'variables';

.sms-journey {
  .mat-horizontal-stepper-header-container {
    margin-top: 18px;
    background: white;
    border-radius: $mainBorderRadius;
    .mat-step-header {
      pointer-events: none !important;
    }

    .mat-step-label {
      min-width: auto;
    }

    .mat-step-icon {
      text-indent: 200px;
      width: 10px;
      height: 10px;
      background: $stepperColor;
      margin-right: 0;
    }

    .mat-stepper-horizontal-line {
      border-top: 3px solid $stepperColor;
      margin: 0 -25px;
    }

    .mat-step-icon-selected {
      background: #ffffff;
      border: 4px solid $primary;
      width: 16px;
      height: 16px;
    }

    .mat-step-icon-state-edit {
      background: $success;
      width: 16px;
      height: 16px;
      position: relative;
      &:after {
        position: absolute;
        display: block;
        content: '';
        background: url('/assets/images/checkbox.svg') no-repeat;
        background-size: cover;
        width: 10px;
        height: 6px;
        left: 3px;
        top: 4px;
      }
    }
  }

  .mat-horizontal-content-container {
    background: #ffffff;
    margin-top: 18px;
    border-radius: $mainBorderRadius;
    padding: 22px 7px;
    overflow: inherit;
  }

  .mat-step-header.cdk-focused,
  .mat-step-header.cdk-program-focused,
  .mat-step-header:hover:not([aria-disabled]),
  .mat-step-header:hover[aria-disabled='false'] {
    background: transparent;
  }
}
