@use 'variables' as v;
@import 'mixins';

.fs-8 {
  font-size: 8px !important;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-16 {
  font-size: 16px;
}

.fs-20 {
  font-size: 20px;
}

.fw-400 {
  font-weight: 400;
}

.fw-700 {
  font-weight: 700;
}

.p-lg {
  font-size: 13px;
  line-height: 20px;
  color: v.$mainColor;
}

.text-grey {
  color: v.$textGrey;
}

.text-main-grey {
  color: v.$grey;
}

.text-black {
  color: v.$black;
}

.text-black-3 {
  color: v.$black3;
}

.text-gray-1 {
  color: v.$mainColor;
}

.text-primary {
  color: v.$primary;
}

.text-white {
  color: v.$white;
}

.text-label {
  color: v.$labelColor;
}

.font-semi-bold {
  font-weight: 600;
}

.cursor-pointer {
  cursor: pointer;
}

.opacity-0 {
  opacity: 0;
}

.btn {
  font-weight: 600;
  font-size: 11px;
  line-height: 18px;
  padding: 10px 20px;
  font-family: 'Open Sans', sans-serif;
  @include tabletL {
    padding: 10px 30px;
  }
  &:focus {
    box-shadow: none;
  }
}

.btn-primary {
  color: #ffffff;
  padding: 10px;
  white-space: nowrap;
  border-radius: 4px;
  @include tabletL {
    padding: 10px 30px;
  }
}

.btn-red,
.btn-red:hover {
  color: v.$danger;
  background: rgba(239, 77, 77, 0.1) !important;
}

.btn-status {
  padding: 3px 8px;
  border-radius: 30px;
  font-weight: 400;

  &.bg-primary {
    background: v.$btn-bg-primary !important;
    color: v.$primary;
  }

  &.bg-secondary {
    background: v.$btn-bg-secondary !important;
    color: v.$white;
  }

  &.bg-info {
    background: v.$btn-bg-info !important;
    color: v.$white;
  }

  &.bg-warning {
    background: v.$btn-bg-warning !important;
    color: v.$warning;
  }

  &.bg-danger {
    background: v.$btn-bg-danger !important;
    color: v.$danger;
  }

  &.bg-light {
    background: v.$btn-bg-light !important;
    color: v.$black;
  }

  &.bg-success {
    background: v.$btn-bg-success !important;
    color: v.$success;
  }

  &.bg-suspended {
    background: v.$btn-bg-suspended !important;
    color: v.$black;
  }

  &.bg-purple {
    background: v.$btn-bg-purple !important;
    color: v.$badgeViolet;
  }
}

.btn-sm {
  padding: 5px 40px;
}

.btn-grey {
  background: v.$btnGrey;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 600;
}

.is-invalid {
  color: v.$danger;
  font-size: 12px;
}

h1 {
  font-size: 1.5rem;
  line-height: 34px;
  font-weight: 700;
  color: v.$black;
  @include tablet {
    font-size: 2.125rem;
  }
}

h2 {
  color: v.$black;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 32px;
  @include tablet {
    font-size: 1.5rem;
  }
  @include tabletMiddle {
    font-size: 1.875rem;
  }
}

h3 {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 26px;
  color: v.$black;
  @include tablet {
    font-size: 1.25rem;
  }
  @include tabletMiddle {
    font-size: 1.5rem;
  }
}

h4 {
  font-size: 1.125rem;
  font-weight: 700;
  color: v.$black;
  line-height: 22px;
  @include tabletMiddle {
    font-size: 1.25rem;
  }
}

h5 {
  font-size: 1rem;
  font-weight: 700;
  color: v.$black;
  line-height: 20px;
  @include tabletMiddle {
    font-size: 1.125rem;
  }
}

h6 {
  font-size: 1rem;
  font-weight: 700;
  color: v.$black;
  line-height: 18px;
}
